import React, { useState, useEffect } from "react";
import Layout from "../Layout";
import axios from "axios";
import "./style.scss";
import loader from "../../assets/spinner.gif";
import { keys } from "../../config";

function Fundraisers() {
  const [data, setData] = useState({
    moneyRaised: 0,
    topContributors: [],
    contributors: [],
  });

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        keys.api_url
      );
      setIsLoading(false);
      console.log(response.data);
      setData(response.data);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  };

  const cards = data.contributors.map((item) => (
    <div className="card">
      <div className="supporter-card">
        <img src={item.img_src}></img>
        <h4>{item.name}</h4>
      </div>
    </div>
  ));

  let content = <img src={loader}></img>;
  if (!isLoading) {
    content = cards;
  }
  return (
    <Layout>
      <div className="fundraisers">
        <h1>Fundraisers</h1>
        <div className="divider"></div>

        <a className="btn" target="_blank" href="https://www.unitedwaymumbai.org/ngo-1317">View the Fundraiser </a>

        <div className="cards"> {content}</div>
      </div>
    </Layout>
  );
}

export default Fundraisers;
